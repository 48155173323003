/* eslint-disable react/display-name */
import React from 'react';

import { Card } from 'src/components/atoms/Card';
import { Grid } from 'src/components/molecules/Grid';
import { Section } from 'src/components/molecules/Section';
import { FixedImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, callouts, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsImageBlock');
  customData = JSON.parse(customData);

  return (
    <Section {...customData?.flags}>
      {!!heading && <h2>{heading}</h2>}
      {!!callouts.length && (
        <Grid centered justify="center" columns={`repeat(${callouts.length}, max-content)`}>
          {callouts.map(({ image }, i) => (
            <Card centered noPadding key={i}>
              <FixedImg constrain width={90} height={90} data={image} />
            </Card>
          ))}
        </Grid>
      )}
    </Section>
  );
}
